/* eslint-disable no-param-reassign */
import i18next from 'i18next';
import _ from 'lodash';

import { alertToast, errorToast } from 'config/toast';

import network from './network';

const hydraMember = ({ 'hydra:member': data }) => data;

export const paginatedMember = ({
  'hydra:member': data,
  'hydra:view': view,
  'hydra:totalItems': totalItems,
}) => ({
  data,
  nextPage: view && view['hydra:next'],
  totalItems,
});

const remove = (id, config) => network
  .delete(`/calculations/${id}`, config)
  .catch(errorToast);

export const all = (params) => network
  .get('/calculations', { params })
  .then(paginatedMember)
  .catch(errorToast);

export const get = (id) => network
  .get(`/calculations/${id}`)
  .catch((err) => {
    errorToast(err);
    return null;
  });
export const getByRef = ref => ref && network
  .get(`${process.env.REACT_APP_API_URL}${ref}`)
  .catch(errorToast);

export const triggerCalculation = calculation => network.post('/calculation_result_journals', {
  calculation: calculation['@id'],
});

export const getExtended = (id) => get(id)
  .then(async (calc) => {
    if (!calc) return { error: true };

    const witExtras = _.clone(calc);
    const shouldFetch = [];

    if (calc.location) {
      shouldFetch.push(
        network
          .get(`${process.env.REACT_APP_API_URL}${calc.location}`)
          .then((r) => ['location', r]),
      );
    }

    if (calc.template) {
      shouldFetch.push(
        network
          .get(`${process.env.REACT_APP_API_URL}${calc.template?.['@id']}`)
          .then((r) => ['template', r]),
      );
    }

    const data = await Promise.all(shouldFetch);

    _.forEach(data, ([key, value]) => {
      witExtras[key] = value;
    });

    return witExtras;
  });

export const getByFolder = (config = {}) => {
  const productName = config?.params?.['order[productName]'];

  if (productName) {
    delete config?.params?.['order[productName]'];
    config.params['order[productName]'] = productName;
  }

  return network
    .get('/calculations', config)
    .then(paginatedMember)
    .catch(errorToast);
};

export const getNextPageByFolder = (url) => network
  .get(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(paginatedMember)
  .catch(errorToast);

export const bulk = {
  accept: (reviews) => network
    .post('/group_actions/accept_reviews', { reviews })
    .then(result => {
      alertToast('Accepted', 'success');
      return result;
    })
    .catch(errorToast),

  complete: (reviews, other) => network
    .post('/group_actions/approve_reviews', { reviews, ...other })
    .then(result => {
      alertToast('Approved!', 'success');
      return result;
    })
    .catch(errorToast),

  copy: (calculations, folder) => network
    .post('/group_actions/copy_calculations', { calculations, folder })
    .then(() => alertToast('Copied!', 'success'))
    .catch(errorToast),

  decline: (reviews, other) => network
    .post('/group_actions/decline_reviews', { reviews, ...other })
    .then(result => {
      alertToast('Declined', 'success');
      return result;
    })
    .catch(errorToast),

  remove: (calculations) => {
    const config = { params: { owner: 'none' } };
    if (calculations.length === 1) return remove(calculations[0].split('/')[3], config);
    return network
      .post('/group_actions/delete_calculations', { calculations }, config)
      .then(() => alertToast(i18next.t('Calculations have been deleted with success!'), 'success'))
      .catch(errorToast);
  },

  move: (calculations, folder) => network
    .post('/group_actions/move_calculations', { calculations, folder })
    .then(() => alertToast('Moved!', 'success'))
    .catch(errorToast),

  purchase: (calculations) => network
    .post('/group_actions/purchase_calculations', { calculations })
    .then(() => alertToast('Purchased!', 'success'))
    .catch(err => errorToast(err, 'Insufficient credits to purchase this calculation')),

  refuse: (reviews, other) => network
    .post('/group_actions/deny_reviews', { reviews, ...other })
    .then(result => {
      alertToast('Denied reviews', 'success');
      return result;
    })
    .catch(errorToast),

  reject: (reviews, other) => network
    .post('/group_actions/reject_reviews', { reviews, ...other })
    .then(result => {
      alertToast('Rejected reviews', 'success');
      return result;
    })
    .catch(errorToast),

  download: (calculations, format) => network
    .post('/group_actions/report_downloads', { calculations, format })
    .then(() => alertToast("We've sent you an email with your download link.", 'success'))
    .catch(error => {
      const err = { error }.error.response.data['hydra:description'];
      alertToast(err, 'error');
      throw error;
    }),

  resubmit: (reviews) => network
    .post('/group_actions/resubmit_reviews', { reviews })
    .then(() => alertToast('Re-submitted to review!', 'success'))
    .catch(errorToast),

  submit: (reviews, reviewer) => network
    .post('/group_actions/submit_reviews', { reviews, reviewer })
    .then(() => alertToast('Submitted to review!', 'success'))
    .catch(errorToast),
};

export const create = (payload) => network.post('/calculations', payload).then((r) => {
  alertToast('The calculation has been created successfully!', 'success');
  return r;
});

const patchNullValues = (payload, except) => {
  const result = { ...payload };
  Object.keys(result).forEach(k => {
    if (result[k] === null
      && (!except || !except.includes(k))
      && !k.endsWith('File')
      && !k.endsWith('Image')
      && !k.endsWith('template')
      && k !== 'predefinedConstructionWaste') result[k] = '';
  });

  return result;
};

export const save = (id, payload) => network
  .patch(
    `/calculations/${id}`,
    patchNullValues(payload),
    { fileHeader: { 'Content-Type': 'application/merge-patch+json' } },
  )
  .then((r) => {
    alertToast('Calculation data updated!', 'success');
    return r;
  });

export const deleteSensativeAnalysisImageFile = url => network
  .delete(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(() => alertToast('Calculation sensitive analysis image deleted with success!', 'success'))
  .catch(errorToast);

export const uploadAllocationImageFile = (payload) => network
  .post('/media_objects', payload, {
    params: { owner: 'none' }, fileHeader: { 'Content-Type': 'multipart/form-data' },
  })
  .then(result => {
    alertToast('File uploaded with success!', 'success');
    return result;
  })
  .catch(() => alertToast('The file couldn’t be uploaded (try another one)', 'error'));

export const deleteAllocationImageFile = url => network
  .delete(`${process.env.REACT_APP_API_URL}${url}`, { params: { owner: 'none' } })
  .then(() => alertToast('File deleted with success!', 'success'))
  .catch(errorToast);

export const uploadSensativeAnalysisImageFile = (id, payload) => network
  .post(`/calculations/${id}/sensativeanalysisimagefile`, payload)
  .catch(errorToast);

export const deleteProductImageFile = (id) => network
  .delete(`/calculations/${id}/productimagefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteProductFlowImageFile = (id) => network
  .delete(`/calculations/${id}/productflowimagefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteProductDescriptionImageFile = (id) => network
  .delete(`/calculations/${id}/productdescriptionimagefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteProductPerformanceFile = (id) => network
  .delete(`/calculations/${id}/productperformancefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteProductionProcessImageFile = (id) => network
  .delete(`/calculations/${id}/productionprocessimagefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteProductionProcessFile = (id) => network
  .delete(`/calculations/${id}/productionprocessfile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteConstructionProcessImageFile = (id) => network
  .delete(`/calculations/${id}/constructionprocessimagefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteConstructionProcessFile = (id) => network
  .delete(`/calculations/${id}/constructionprocessfile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const deleteConstructionWasteFile = (id) => network
  .delete(`/calculations/${id}/constructionwastefile`)
  .then(() => alertToast('Calculation data updated!', 'success'))
  .catch(errorToast);

export const uploadProductImageFile = (id, payload) => network
  .post(`/calculations/${id}/productimagefile`, payload)
  .catch(errorToast);

export const uploadProductFlowImageFile = (id, payload) => network
  .post(`/calculations/${id}/productflowimagefile`, payload)
  .catch(errorToast);

export const uploadProductDescriptionImageFile = (id, payload) => network
  .post(`/calculations/${id}/productdescriptionimagefile`, payload)
  .catch(errorToast);

export const uploadProductPerformanceFile = (id, payload) => network
  .post(`/calculations/${id}/productperformancefile`, payload)
  .catch(errorToast);

export const uploadProductionProcessImageFile = (id, payload) => network
  .post(`/calculations/${id}/productionprocessimagefile`, payload)
  .catch(errorToast);

export const uploadProductionProcessFile = (id, payload) => network
  .post(`/calculations/${id}/productionprocessfile`, payload)
  .catch(errorToast);

export const uploadConstructionProcessImageFile = (id, payload) => network
  .post(`/calculations/${id}/constructionprocessimagefile`, payload)
  .catch(errorToast);

export const uploadConstructionProcessFile = (id, payload) => network
  .post(`/calculations/${id}/constructionprocessfile`, payload)
  .catch(errorToast);

export const uploadConstructionWasteFile = (id, payload) => network
  .post(`/calculations/${id}/constructionwastefile`, payload)
  .catch(errorToast);

export const inputs = (id, params) => network
  .get(`/calculations/${id}/inputs`, { params, headers: { Accept: 'application/ld+json' } })
  .then(paginatedMember)
  .catch(errorToast);

export const input = (inputId) => network
  .get(`/inputs/${inputId}`)
  .catch(errorToast);

export const removeInput = (inputId) => network
  .delete(`/inputs/${inputId}`)
  .catch(errorToast);

export const saveInput = (payload, inputId) => {
  const [verb, url] = inputId ? ['put', `/inputs/${inputId}`] : ['post', '/inputs'];

  return network[verb](url, payload)
    .then(r => {
      if (inputId) alertToast('Calculation input data updated!', 'success');
      else alertToast('Calculation input data created!', 'success');
      return r;
    })
    .catch(errorToast);
};

export const usages = id => id && network
  .get(`/product_calculations/${id}/semi_finished_product_usages`)
  .then(hydraMember)
  .catch(errorToast);

export const usage = id => id && network
  .get(`/semi_finished_product_usages/${id}`)
  .catch(errorToast);

export const removeUsage = (usageId) => network
  .delete(`/semi_finished_product_usages/${usageId}`)
  .catch(errorToast);

export const saveUsage = (payload, inputId) => {
  const [verb, url] = inputId
    ? ['put', `/semi_finished_product_usages/${inputId}`]
    : ['post', '/semi_finished_product_usages'];

  return network[verb](url, payload).catch(errorToast);
};

export const copyInput = (id) => network
  .post(`/inputs/${id}/copy`)
  .then(() => alertToast('Input copied with success!', 'success'))
  .catch(errorToast);

export const createReport = (params) => network
  .post('/reports', params)
  .catch(errorToast);

export const getReport = (id, params) => network
  .get(`/reports/${id}`, params)
  .catch(err => {
    alertToast(i18next.t('There was an error accessing the report, please try again.'), 'error');
    return err;
  });

export const getReportRef = (id, params) => network
  .get(`${process.env.REACT_APP_API_URL}${id}`, params)
  .catch(errorToast);

export const getAllSfpsForDropdown = (params) => network
  .get('/semi_finished_products', { params })
  .then(paginatedMember)
  .catch(errorToast);

export const getTemplates = (params) => network
  .get('/templates', { params })
  .then(paginatedMember)
  .catch(errorToast);

export const deleteTemplates = (id) => network
  .delete(`/templates/${id}`)
  .then(result => {
    alertToast(i18next.t('The template has been deleted successfully!'), 'success');
    return result;
  })
  .catch(errorToast);

export const copyTemplate = id => network
  .post('/group_actions/copy_templates', { templates: [id] })
  .then(() => alertToast('Copied!', 'success'))
  .catch(errorToast);

export const createTemplate = (params) => network
  .post('/templates', params)
  .then(result => {
    alertToast(i18next.t('The template has been created successfully!'), 'success');
    return result;
  })
  .catch(errorToast);

export const getTemplateDetail = (id) => network
  .get(`/templates/${id}`)
  .catch(errorToast);

export const saveTemplate = (id, payload) => network
  .patch(
    `/templates/${id}`,
    patchNullValues(payload, ['publisher', 'operator']),
    { fileHeader: { 'Content-Type': 'application/merge-patch+json' } },
  )
  .then((r) => {
    alertToast(i18next.t('The template has been updated successfully!'), 'success');
    return r;
  });

export const getTemplateCalculations = (id, params) => network
  .get(`/templates/${id}/calculations`, { params })
  .then(paginatedMember)
  .catch(errorToast);
